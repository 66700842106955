// Utils
import { api } from "@utils/";

// Constants
import { API_ROUTES } from "@constants/";

const getCharHistory = async () => {
  const response = await api.get(`${API_ROUTES.chat}/all`, {});
  return response?.data;
};

const getSingleChat = async (chatId: string) => {
  const response = await api.get(`${API_ROUTES.chat}/${chatId}`, {});
  return response?.data;
};

const deleteChat = async (chatId: string) => {
  const response = await api.destroy(`${API_ROUTES.chat}/${chatId}`);
  return response;
};

const renameChat = async (id: string, data: unknown) => {
  const response = await api.patch(`${API_ROUTES.chat}/${id}`, {}, data);
  return response?.data;
};

const createChat = async (data: unknown) => {
  const response = await api.post(API_ROUTES.chat, {}, data);
  return response?.data;
};

export { getCharHistory, getSingleChat, deleteChat, renameChat, createChat };

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Menu, Tooltip, Drawer } from "@mantine/core";
import { Check, Ellipsis, PanelLeft, Pencil, SquarePen, Trash, X } from "lucide-react";
import { useState } from "react";

// Api
import { renameChat, useGetChatHistory } from "@api/";

// Component
import { Visible } from "@components/";

// Utils
import { error, success } from "@utils/";

// Hooks
import { useInvalidate, useIsMobile } from "@hooks/";

// Constants
import { KEY_CHAT_HISTORY_GET_ALL } from "@constants/";

type TChatHistory = {
  id: string;
  name: string;
  user_id: number;
};

type TChatHistoryProps = {
  activeChat: any;
  setActiveChat: any;
  handleOpenChat: (arg0: TChatHistory) => void;
  handleDeleteChat: (arg0: TChatHistory) => void;
  handleCreateChat: (arg0: boolean) => void;
};

const ChatHistory = ({
  activeChat,
  setActiveChat,
  handleOpenChat,
  handleDeleteChat,
  handleCreateChat,
}: TChatHistoryProps) => {
  const isMobile = useIsMobile();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { chatHistory } = useGetChatHistory();
  const [editingChatId, setEditingChatId] = useState<string | null>(null);
  const [editingChatName, setEditingChatName] = useState<string | null>(null);
  const [drawerOpened, setDrawerOpened] = useState(false);
  const { invalidate } = useInvalidate();

  const handleCollapseToggle = () => {
    setIsCollapsed((prevState) => !prevState);
  };

  const handleChatClick = (chat: TChatHistory) => {
    setEditingChatId(null);
    setEditingChatName(null);

    if (chat.id !== activeChat?.id) {
      setActiveChat(chat);
      handleOpenChat(chat);
    }
  };

  const onRenameChat = async () => {
    if (!editingChatId || !editingChatName) return;

    try {
      const response = await renameChat(editingChatId, { name: editingChatName });
      if (response) {
        setEditingChatId(editingChatId);
        success({ message: "Chat renamed successfully" });
        setEditingChatId(null);
        setEditingChatName(null);
        invalidate(KEY_CHAT_HISTORY_GET_ALL);
      }
    } catch (err) {
      error({ title: "Something went wrong" });
    }
  };

  const renderChatList = () => {
    if (!chatHistory?.data || chatHistory.data.length === 0) {
      return <p className="text-gray-500 text-sm">No chats available</p>;
    }

    return chatHistory.data.map((chat: TChatHistory, index: number) => {
      const isActive = chat.id === activeChat?.id;
      const isEditing = chat.id === editingChatId;

      return isEditing ? (
        <div key={index} className="flex w-full items-center">
          <input
            type="text"
            value={editingChatName || ""}
            onChange={(e) => setEditingChatName(e.target.value)}
            className="h-7 w-4 flex-grow rounded border px-2 py-1 text-sm"
          />
          <div className="flex items-center">
            <button onClick={onRenameChat} className="ml-2 flex items-center rounded bg-lightGreen p-1">
              <Check className="h-4 w-4" />
            </button>
            <button
              onClick={() => {
                setEditingChatId(null);
                setEditingChatName(null);
              }}
              className="ml-2 flex items-center rounded bg-red/20 p-1"
            >
              <X className="h-4 w-4" />
            </button>
          </div>
        </div>
      ) : (
        <Tooltip label={chat.name} color="gray" position="right" key={index}>
          <div
            onClick={() => handleChatClick(chat)}
            className={`mb-xxs flex w-full cursor-pointer items-center justify-between rounded-md p-1 text-sm ${
              isActive ? "text-dark bg-braytron-lightGray" : "hover:bg-braytron-lightGray"
            }`}
          >
            <p className="w-full max-w-32 overflow-hidden text-ellipsis whitespace-nowrap">{chat.name}</p>
            <Menu width={200} position="bottom" withArrow>
              <Menu.Target>
                <Ellipsis onClick={(e) => e.stopPropagation()} className="h-4" />
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Label>Actions</Menu.Label>
                <Menu.Item
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditingChatId(chat.id);
                    setEditingChatName(chat.name);
                  }}
                >
                  <p className="flex items-center">
                    <Pencil className="mr-2 h-4" /> Rename
                  </p>
                </Menu.Item>
                <Menu.Item
                  color="red"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteChat(chat);
                  }}
                >
                  <p className="flex items-center">
                    <Trash className="mr-2 h-4" /> Delete
                  </p>
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </div>
        </Tooltip>
      );
    });
  };

  if (isMobile) {
    return (
      <div className="relative">
        <div className="z-100 absolute top-0">
          <PanelLeft onClick={() => setDrawerOpened(true)} className="h-7 w-7 rounded-md bg-braytron-lightGray p-1" />

          <SquarePen
            onClick={() => {
              handleCreateChat(true);
              setActiveChat(null);
            }}
            className="mt-2 h-7 w-7 rounded-md bg-braytron-lightGray p-1"
          />
        </div>
        <Drawer
          opened={drawerOpened}
          onClose={() => setDrawerOpened(false)}
          title="Chat History"
          padding="md"
          size="sm"
        >
          <div className="h-full overflow-y-auto">{renderChatList()}</div>
        </Drawer>
      </div>
    );
  }

  return (
    <section
      className={`h-full ${isCollapsed ? "w-10" : "w-72"} rounded-md border border-braytron-lightGray bg-offwhite p-2 transition-all duration-300 ease-in-out`}
    >
      <div className={`flex ${isCollapsed ? "flex-col" : "flex-row justify-between"} gap-y-2 text-darkGray`}>
        <Tooltip label="Collapse" color="gray" position="right">
          <button className="hover:text-braytron-textDark" onClick={handleCollapseToggle}>
            <PanelLeft />
          </button>
        </Tooltip>
        <Tooltip label="New chat" color="gray" position="right">
          <button className="hover:text-braytron-textDark">
            <SquarePen
              onClick={() => {
                handleCreateChat(true);
                setActiveChat(null);
              }}
            />
          </button>
        </Tooltip>
      </div>
      <Visible when={!isCollapsed}>
        <div className="h-[calc(100vh-150px)] overflow-y-auto pt-lg">{renderChatList()}</div>
      </Visible>
    </section>
  );
};

export { ChatHistory };

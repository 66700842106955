import { useIsMobile } from "@hooks/";
import { User } from "lucide-react";

type TUserChatMessage = {
  message: string;
};

const UserChatMessage = ({ message }: TUserChatMessage) => {
  const isMobile = useIsMobile();

  return (
    <div className="flex h-fit w-full p-2 pl-12 md:pl-28">
      <div className="mr-sm h-full w-full rounded-md bg-braytron-10 px-3 py-2 text-sm">
        <p>{message}</p>
      </div>

      <div
        className={`flex shrink-0 items-center justify-center rounded-full bg-braytron-60 ${isMobile ? "h-7 w-7" : "h-9 w-9"}`}
      >
        <User className={`text-white ${isMobile ? "h-4 w-4" : "h-5 w-5"}`} />
      </div>
    </div>
  );
};

export { UserChatMessage };

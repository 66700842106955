/* eslint-disable @typescript-eslint/no-explicit-any */
import { Textarea } from "@mantine/core";
import { Send } from "lucide-react";
import { useEffect, useRef, useState } from "react";

// Components
import { Visible } from "@components/";
import { AIChatMessage } from "./AIChatMessage";
import { UserChatMessage } from "./UserChatMessage";

// Enums
import { EChat } from "@enums/";

// Hooks
import { useInvalidate, useSocket } from "@hooks/";

// Constants
import { KEY_CHAT_HISTORY_GET_ALL, LS_ID, WS_SEND_MESSAGE } from "@constants/";

// Utiils
import { createChat, useDeleteChat, useGetSingleChat } from "@api/";
import { error, getItem } from "@utils/";
import { ChatHistory } from "./ChatHistory";
import { ChatLoading } from "./ChatLoading";

type TChatMessages = {
  message: string;
  type: EChat;
};

type TChatHistory = {
  id: string;
  name: string;
  user_id: number;
  type?: EChat;
  is_from_user?: boolean;
};

const ChatAIContent = () => {
  const userId = getItem(LS_ID);
  const listRef = useRef<HTMLDivElement>(null);
  const { invalidate } = useInvalidate();

  const socket = useSocket();
  const [message, setMessage] = useState("");
  const [chatId, setChatId] = useState("");
  const [activeChat, setActiveChat] = useState<TChatHistory>();

  const [chatMessages, setChatMessages] = useState<TChatMessages[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { singleChat, isSingleChatLoading } = useGetSingleChat(chatId);
  const { deleteSingleChat } = useDeleteChat();

  const onSendMessage = async () => {
    // Prevent sending empty messages
    if (!message.trim()) return;

    if (chatMessages.length === 0) {
      await onCreateNewChat();
      return;
    } else {
      const sendChatData = {
        prompt: message,
        uuid: activeChat?.id,
        user_id: userId,
      };

      if (socket.connected) {
        (socket as any)?.emit(WS_SEND_MESSAGE, sendChatData);
        setChatMessages((prevMessages) => [...prevMessages, { message, type: EChat.USER }]);
        setMessage("");
        setIsLoading(true);
      }
    }
  };

  const onCreateNewChat = async () => {
    if (!message || !socket.connected) return;

    try {
      const response = await createChat({ name: message });
      if (response) {
        setActiveChat(response.data);
        setChatId(response.data.id);
        const sendChatData = {
          prompt: message,
          uuid: response.data.id,
          user_id: userId,
        };

        (socket as any)?.emit(WS_SEND_MESSAGE, sendChatData);

        setChatMessages((prevMessages) => [...prevMessages, { message, type: EChat.USER }]);
        setMessage("");
        setIsLoading(true);
        invalidate(KEY_CHAT_HISTORY_GET_ALL);
      }
    } catch (err) {
      error({ title: "Something went wrong" });
    }
  };

  const openChat = (chatData: TChatHistory) => {
    setChatMessages([]);
    setActiveChat(chatData);
    setChatId(chatData.id);
  };

  const handleDeleteChat = (chat: TChatHistory) => {
    if (activeChat?.id === chat.id) {
      setActiveChat(undefined);
      setChatId("");
      setChatMessages([]);
    }

    deleteSingleChat({ id: chat.id });
  };

  useEffect(() => {
    const getMessageFromAI = (data: any) => {
      const { data: message } = JSON.parse(data);
      setChatMessages((prevMessages) => [...prevMessages, { message, type: EChat.AI }]);
      setIsLoading(false);
    };
    (socket as any)?.on(activeChat?.id, getMessageFromAI);

    return () => {
      (socket as any)?.off(activeChat?.id, getMessageFromAI);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChat]);

  useEffect(() => {
    if (chatId && singleChat && singleChat.length !== 0) {
      singleChat.data.messages.forEach((singleMessage: TChatHistory) => {
        singleMessage.type = singleMessage.is_from_user ? EChat.USER : EChat.AI;
      });
      setChatMessages(singleChat.data.messages);
    }
  }, [chatId, singleChat]);

  useEffect(() => {
    function scrollToLastMessage() {
      if (listRef.current) {
        listRef.current!.lastElementChild?.scrollIntoView({
          block: "start",
          inline: "nearest",
          behavior: "smooth",
        });
      }
    }

    scrollToLastMessage();
  }, [chatMessages, chatId]);

  return (
    <div className="flex h-[calc(100vh-80px)] w-full gap-x-2">
      <ChatHistory
        handleOpenChat={openChat}
        handleDeleteChat={handleDeleteChat}
        handleCreateChat={() => {
          setActiveChat(undefined);
          setChatMessages([]);
          setChatId("");
        }}
        activeChat={activeChat}
        setActiveChat={setActiveChat}
      />
      <section className="flex h-full w-full flex-col transition-all duration-300 ease-in-out">
        {/* Message list */}
        <div className="max-h-[calc(100vh-10rem)] flex-grow overflow-y-auto pr-sm" ref={listRef}>
          <Visible when={chatMessages.length === 0 && !isSingleChatLoading}>
            <p className="flex w-full items-center justify-center py-8 text-braytron-darkGray">What can i help with?</p>
          </Visible>

          <Visible when={isSingleChatLoading}>
            <p className="flex w-full items-center justify-center py-8 text-braytron-darkGray">Loading...</p>
          </Visible>

          <Visible when={chatMessages.length !== 0 && !isSingleChatLoading}>
            {chatMessages.map((msg: TChatMessages, index) => {
              if (msg.type === EChat.USER) {
                return <UserChatMessage key={index} message={msg.message} />;
              } else {
                return <AIChatMessage key={index} message={msg.message} />;
              }
            })}
          </Visible>
          <Visible when={isLoading}>
            <ChatLoading />
          </Visible>
        </div>

        {/* Input message */}
        <section className="flex w-full rounded-md border border-braytron-lightGray bg-offwhite p-2">
          <Textarea
            placeholder="Message BraytronChatAI"
            className="flex-grow"
            rows={4}
            value={message}
            onChange={(event) => setMessage(event.currentTarget.value)}
            styles={{
              input: {
                border: "none",
                background: "#FAFAFA",
              },
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.keyCode === 13) {
                e.preventDefault();
                onSendMessage();
              }
            }}
          />

          <div className="flex h-full w-12 items-end justify-end">
            <button
              className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-braytron-60 hover:bg-braytron-80"
              onClick={() => {
                onSendMessage();
              }}
            >
              <Send className="h-5 w-5 text-white" />
            </button>
          </div>
        </section>
      </section>
    </div>
  );
};

export { ChatAIContent };

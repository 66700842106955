import { useIsMobile } from "@hooks/";
import { Brain } from "lucide-react";

const ChatLoading = () => {
  const isMobile = useIsMobile();

  return (
    <div className="flex h-fit w-full p-2 pr-28">
      <div
        className={`flex shrink-0 ${isMobile ? "!h-7 !w-7" : "h-9 w-9"} items-center justify-center rounded-full bg-braytron-textDark`}
      >
        <Brain className={`text-white ${isMobile ? "h-4 w-4" : "h-5 w-5"}`} />
      </div>

      <div className="ml-sm flex h-full w-full gap-x-2 rounded-md px-3 py-2 pt-md text-sm">
        <div className="h-3 w-3 animate-bounce rounded-full bg-braytron-textDark [animation-delay:-0.3s]"></div>
        <div className="h-3 w-3 animate-bounce rounded-full bg-braytron-textDark [animation-delay:-0.15s]"></div>
        <div className="h-3 w-3 animate-bounce rounded-full bg-braytron-textDark"></div>
      </div>
    </div>
  );
};

export { ChatLoading };

import { useState, useEffect } from "react";

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 780);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 779px)");
    const handleResize = () => setIsMobile(mediaQuery.matches);

    // Add the listener
    mediaQuery.addEventListener("change", handleResize);

    // Initial check
    handleResize();

    // Cleanup the listener on component unmount
    return () => mediaQuery.removeEventListener("change", handleResize);
  }, []);

  return isMobile;
};
export { useIsMobile };

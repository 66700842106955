const COLORS = {
  "braytron-10": "#e3f6ff",
  "braytron-20": "#cce7ff",
  "braytron-30": "#9ccdfe",
  "braytron-40": "#67b2fc",
  "braytron-50": "#3c9afb",
  "braytron-60": "#228bfa",
  "braytron-70": "#0d84fb",
  "braytron-80": "#0071e0",
  "braytron-90": "#0065ca",
  "braytron-100": "#0057b3",
  "braytron-textDark": "#2d2d2e",
  "braytron-lightGray": "#efefef",
  "braytron-darkGray": "#8c8c8f",
  "white": "#ffffff",
  "black": "#000",
  "gray": "#dee2e6",
  "darkGray": "#a1a1a1",

  "red": "#b81435",
  "offwhite": "#FAFAFA",
  "lightGreen": "#b5ffa3",
  "darkGreen": "#1c8902",
};

export { COLORS };

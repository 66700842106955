const CLIENT_ROUTES = {
  root: "/",
  login: "/login",
  requestPasswordReset: "/request-password-reset",
  resetPassword: "/reset-password",
  configuration: "/configuration",
  loyalty: {
    classifications: "/classifications",
    folders: "/folders",
    requirements: "/requirements",
    benefits: "/benefits",
    ranks: "/ranks",
  },
  administration: {
    users: "/users",
  },
};

const API_ROUTES = {
  login: "/login",
  requestPasswordReset: "/forgot-password",
  resetPassword: "/reset-password",
  user: "/users",
  benefits: "/benefits",
  folders: "/folders",
  requirements: "/requirements",
  ranks: "/ranks",
  classifications: "/classifications",
  configuration: "/requirements/table-column",
  customers: "/customers",
  configurationMassUpdate: "/client-db-tables/mass-update-active-records",
  users: "/users",
  chat: "/chat-rooms",
};

export { API_ROUTES, CLIENT_ROUTES };

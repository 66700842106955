import { useQuery } from "@tanstack/react-query";

// Functions
import { deleteChat, getCharHistory, getSingleChat } from "@api/";

// Constants
import { KEY_CHAT_GET_ONE, KEY_CHAT_HISTORY_GET_ALL } from "@constants/";

// Utils
import { error, success } from "@utils/";

// hooks
import { useInvalidate } from "@hooks/";

export const useGetChatHistory = () => {
  const {
    data: chatHistory,
    isLoading: isChatHistoryLoading,
    isFetching,
  } = useQuery({
    queryKey: [KEY_CHAT_HISTORY_GET_ALL],
    queryFn: () => getCharHistory(),
    initialData: [],
    enabled: true,
  });

  return { chatHistory, isChatHistoryLoading: isChatHistoryLoading || isFetching };
};

export const useGetSingleChat = (chatId: string) => {
  const {
    data: singleChat,
    isLoading: isSingleChatLoading,
    isFetching,
  } = useQuery({
    queryKey: [KEY_CHAT_GET_ONE, chatId],
    queryFn: () => getSingleChat(chatId),
    initialData: [],
    enabled: !!chatId,
  });

  return { singleChat, isSingleChatLoading: isSingleChatLoading || isFetching };
};

export const useDeleteChat = () => {
  const { invalidate } = useInvalidate();

  const deleteSingleChat = async (data: { id: string }) => {
    try {
      const response = await deleteChat(data?.id);
      if (response) {
        success({ message: response?.data?.message });
        invalidate(KEY_CHAT_HISTORY_GET_ALL);
      }
    } catch (err) {
      error({ title: "Something went wrong" });
    }
  };

  return { deleteSingleChat };
};

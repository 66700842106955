import { useEffect, useRef, useState } from "react";
import { Brain } from "lucide-react";

import { CodeHighlight } from "@mantine/code-highlight";
import "@mantine/code-highlight/styles.css";
import { useIsMobile } from "@hooks/";

type TAIChatMessage = {
  message: string;
};

const AIChatMessage = ({ message }: TAIChatMessage) => {
  const [parentWidth, setParentWidth] = useState<number | undefined>(undefined);
  const parentRef = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (entries[0].target instanceof HTMLElement) {
        // Adjust for padding and borders if needed
        const computedStyle = getComputedStyle(entries[0].target);
        const paddingLeft = parseFloat(computedStyle.paddingLeft || "0");
        const paddingRight = parseFloat(computedStyle.paddingRight || "0");
        const borderLeft = parseFloat(computedStyle.borderLeftWidth || "0");
        const borderRight = parseFloat(computedStyle.borderRightWidth || "0");

        const adjustedWidth = entries[0].contentRect.width - paddingLeft - paddingRight - borderLeft - borderRight;
        setParentWidth(adjustedWidth);
      }
    });

    if (parentRef.current) {
      observer.observe(parentRef.current);
    }

    return () => {
      if (parentRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(parentRef.current);
      }
    };
  }, []);

  const transformMessage = (message: string) => {
    const parts: Array<{ type: "code" | "text"; content: string }> = [];
    const regex = /```([\s\S]*?)```/g;
    let lastIndex = 0;

    let match;
    while ((match = regex.exec(message)) !== null) {
      if (match.index > lastIndex) {
        parts.push({ type: "text", content: message.slice(lastIndex, match.index) });
      }
      parts.push({ type: "code", content: match[1] });
      lastIndex = regex.lastIndex;
    }

    if (lastIndex < message.length) {
      parts.push({ type: "text", content: message.slice(lastIndex) });
    }

    return parts;
  };

  const parsedMessage = transformMessage(message);

  return (
    <div ref={parentRef} className="flex h-fit w-[80%] p-2">
      <div
        className={`flex shrink-0 items-center justify-center rounded-full bg-braytron-textDark ${isMobile ? "h-7 w-7" : "h-9 w-9"}`}
      >
        <Brain className={`text-white ${isMobile ? "h-4 w-4" : "h-5 w-5"}`} />
      </div>

      <div className="ml-sm h-full w-full max-w-full rounded-md bg-braytron-lightGray px-3 py-2 text-sm">
        {parsedMessage.map((part, index) =>
          part.type === "code" ? (
            <div
              key={index}
              className="relative my-md overflow-x-scroll rounded-sm bg-braytron-100"
              style={{
                width: parentWidth ? `${parentWidth}px` : "auto", // Dynamically adjust width
                maxWidth: "inherit", // Ensure it doesn't dictate the parent's width
                boxSizing: "border-box", // Proper width calculation including padding
              }}
            >
              <CodeHighlight
                code={part.content}
                language="tsx"
                classNames={{
                  copy: "absolute top-2 right-2 z-10 bg-braytron-lightGray text-braytron-textDark",
                }}
              />
            </div>
          ) : (
            <p key={index}>{part.content}</p>
          ),
        )}
      </div>
    </div>
  );
};

export { AIChatMessage };
